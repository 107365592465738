.label {
  margin-bottom: $spacing-02;
  text-transform: uppercase;
  text-decoration: none;
  font-weight: 600;
  @include font-sans;
  @include typescale-06;

  a {
    text-decoration: none;
  }
}
