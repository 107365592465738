.cite {
  @include font-sans;
  @include typescale-06;

  h2 {
    @include font-serif;
  }

  h3 {
    margin-bottom: $spacing-01;
    font-size: 1em;
    font-weight: 800;
  }
}
