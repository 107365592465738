.pagination {
  display: flex;
  color: $grey;
  line-height: 1.15;
  @include typescale-06;

  a {
    text-decoration: none;
    -webkit-hyphens: auto;
    -moz-hyphens: auto;
    hyphens: auto;
  }

  svg {
    width: 15px;
    height: auto;
  }

  &__prev {
    flex-grow: 1;
    margin-right: 1em;
  }

  &__next {
    flex-grow: 1;
    text-align: right;
    margin-left: 1em;
  }
}
