.infobox {
  margin-top: $spacing-05;
  padding: $spacing-04;
  background-color: rgba($light-grey, 0.35);
  @include typescale-06;

  h2 {
    margin-top: 0;
  }

  a {
    text-decoration: none;
  }

  p:last-child {
    margin-bottom: 0;
  }

  &__links {
    margin-top: $spacing-03;
    font-weight: 800;
    @include font-sans;
  }

  &--authors {
    border-top: 5px solid $red;

    h3 {
      margin-top: 0;
      font-size: 1em;
      font-weight: 800;
      line-height: 1.15;
      @include font-serif;
    }

    article {
      margin-bottom: $spacing-05;

      &:last-child {
        margin-bottom: 0;
      }
    }

    p {
      margin-bottom: $spacing-03;
    }

    svg {
      height: 10px;
      width: auto;
    }

    .logo {
      display: block;
      margin-bottom: $spacing-04;
      height: 30px;
      width: auto;

      @media (min-width: 768px) {
        padding-right: $spacing-04;
        height: auto;
        width: 100%;
      }
    }

    .infobox__links {
      color: $black;

      a {
        display: block;
        margin-top: $spacing-03;
      }
    }
  }

  &--join {
    margin: 0 0 $spacing-04 -40px; // bleed to the left
    line-height: 1.65;
    @include font-sans;

    h2 {
      display: inline;
      font-size: 1em;
      font-weight: 800;

      a {
        border-bottom: 2px solid $black;
        color: $black;
        text-decoration: none;
      }
    }

    p:first-of-type {
      display: inline;
    }

    .infobox__links {
      a {
        color: $red;
      }
    }
  }
}
