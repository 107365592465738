.page-sidebar {
  background: $black;
  color: $white;
  font-size: 18px;

  @media (min-width: 768px) {
    width: 33.3333333%;
    min-height: 100vh;
  }

  @media (min-width: 1200px) {
    position: fixed;
    left: 0;
  }

  h1 {
    margin-top: 0;
    @include typescale-02;
  }

  .label a {
    color: $white;
  }

  &__home {
    display: block;
    margin-bottom: $spacing-04;

    svg {
      fill: transparent;
      transition: fill 250ms ease;

      &:hover, &:focus, &:active {
        fill: $white;
      }
    }
  }
}

.page-sidebar-content {
  @media (min-width: 1200px) {
    margin-left: 33.3333333%;
  }

  &__bleed {
    @media (min-width: 768px) {
      margin-right: -16.6666667%;
    }
  }
}

.page-sidebar-aside {
  @include typescale-06;

  h2 {
    margin-top: $spacing-04;
    padding-top: $spacing-03;
    border-top: 5px solid $black;
    font-size: 1em;
    font-weight: 400;
  }

  .tags {
    margin-bottom: $spacing-03;
  }

  @media (min-width: 768px) {
    width: 16.6666667%;
    margin: $spacing-05 0;
  }
}

.page-sidebar-footer {
  @media (min-width: 768px) {
    margin-left: 33.3333333%;
  }
}
