/* Mixins for typescale in minor third
   Use with headings and text only
   Base unit: 20px */

@mixin typescale-01 {
  font-size: 2.488em;
}

@mixin typescale-02 {
  font-size: 2.074em;
}

@mixin typescale-03 {
  font-size: 1.728em;
}

@mixin typescale-04 {
  font-size: 1.44em;
}

@mixin typescale-05 {
  font-size: 1.2em;
}

@mixin typescale-06 {
  font-size: 0.833em;
}
