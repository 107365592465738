/* Utilities mixins & classes */

@mixin unstyled-list {
  padding-left: 0;
  margin: 0;
  list-style: none;

  &:last-of-type {
    margin-bottom: 0;
  }
}


.print-only {
  display: none;
}

.screen-only {
  @media print {
    display: none;
  }
}

// Only display content to screen readers
//
// See: https://a11yproject.com/posts/how-to-hide-content/
// See: https://hugogiraudel.com/2016/10/13/css-hide-and-seek/

.sr-only {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important; // Fix for https://github.com/twbs/bootstrap/issues/25686
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}
