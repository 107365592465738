.toc__heading {
  padding: $spacing-05 0;
  margin: 0;
  text-align: center;
  background-color: $black;
  color: $white;
}

.toc__down {
  margin-bottom: $spacing-06;
  text-align: center;
}

.toc {
  @include unstyled-list;

  h3, h4, h5 {
    margin: 0 0 $spacing-02 0;
    font-size: 1em;
    line-height: 1.15;
    @include font-serif;
  }
}

.toc__item {
  padding: $spacing-04 0;
  border-bottom: 1px solid rgba($black, 0.5);

  &:last-child {
    border: 0;
  }

  > h4, article, ul {
    margin-left: 0;
    padding-left: 0;
    list-style: none;
  }
}

.toc__featured {
  margin: $spacing-04 0;
  padding: $spacing-04;
  border-left: 5px solid $red;
  background-color: rgba($light-grey, 0.35);

  h4 {
    margin-bottom: 0;
    @include typescale-03;
  }

  a {
    color: $black;

    &:hover,
    &:focus,
    &:active {
      color: $red;
    }
  }

  .label, .label a {
    color: $grey;
    font-size: small;
  }

  .metadata {
    margin-top: $spacing-03;
  }

  &-desc {
    margin: $spacing-05 0 0 0;
  }
}

.toc__link {
  display: block;
  padding-right: $spacing-03;
  text-decoration: none;
  font-size: 1em;
  color: $red;

  &:hover,
  &:focus,
  &:active {
    color: $black;
  }

  &--arrow:before {
    display: inline-block;
    margin-right: .5em;
    height: .75em;
    width: .75em;
    content: '';
    background-image: url("data:image/svg+xml,%3Csvg width='21' height='16' viewBox='0 0 21 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M20.7071 8.70711C21.0976 8.31658 21.0976 7.68342 20.7071 7.29289L14.3431 0.928932C13.9526 0.538408 13.3195 0.538408 12.9289 0.928932C12.5384 1.31946 12.5384 1.95262 12.9289 2.34315L18.5858 8L12.9289 13.6569C12.5384 14.0474 12.5384 14.6805 12.9289 15.0711C13.3195 15.4616 13.9526 15.4616 14.3431 15.0711L20.7071 8.70711ZM0 9L20 9V7L0 7L0 9Z' fill='%231F2222' fill-opacity='0.5'/%3E%3C/svg%3E%0A");
    background-size: contain;
    background-repeat: no-repeat;
    vertical-align: middle;
  }
}

.toc__option a {
  margin: 0;
  color: $grey;
  text-decoration: none;
  @include font-sans;
  @include typescale-06;
}
