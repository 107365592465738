.page-footer {
  margin-top: $spacing-05;
  padding-top: $spacing-05;
  border-top: 1px solid rgba($grey, 0.25);

  > .col {
    align-self: flex-end;
  }

  a {
    text-decoration: none;
  }

  svg {
    height: $spacing-04;
    width: auto;
  }
}

.page-footer__logo {
  margin: 0 0 $spacing-04 0;
  text-align: right;
}
