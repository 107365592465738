@media print {

  body, h1, h2, h3, h4, h5, h6, ol, ul, li, div, span, p, section, article, blockquote, aside, main, header {
    // really ugly to make sure that text doesn’t get cut off
    display: block !important;
    width: auto !important;
    float: none !important;
    position: static !important;
    overflow: visible !important;
  }

  .print-only {
    display: block;
  }

  .page-sidebar {
    position: relative;
    color: $black;

    a, .label a {
      color: $black;
    }
  }

  .page-content {
    position: relative;

    p, li, blockquote {
      position: relative;
      display: block;
      page-break-inside: avoid;
    }
  }

  .page-footer {
    display: block;
    padding-top: 0;
    border: 0;
  }

  .post .page-content,
  .page-footer {
    margin-top: $spacing-03;
  }

  .infobox--authors {
    padding-left: 0;
    padding-right: 0;
  }

  .page-footer__logo {
    text-align: left;
  }

  .anchorjs-link,
  .pagination,
  .page-sidebar-aside,
  .post__summary,
  .post__read-time,
  .sr-only,
  .infobox--join,
  .button,
  .infobox__links,
  .page-sidebar-aside {
    display: none !important;
  }
}
