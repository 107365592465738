/* Buttons */

button,
.button {
  display: inline-block;
  margin-right: $spacing-03;
  padding: $spacing-03;
  cursor: pointer;
  border: 2px solid $red;
  background-color: transparent;
  color: $red;
  transition: all 150ms ease-in;
  text-decoration: none;
  line-height: 1;
  @include typescale-06;
  @include font-sans;

  &:hover,
  &:active {
    background-color: $red;
    color: $white;
  }
}

.button--cta {
  background-color: $red;
  color: $white;

  &:hover,
  &:active,
  &:focus {
    border-color: $black;
    background-color: $black;
    color: $white;
  }
}


.button-group {
  display: flex;

  .button {
    margin-right: 0;

    &:not(:first-child) {
      margin-left: -2px;
    }
  }
}
