.post {

  .page-content {
    margin-top: $spacing-05;
    padding-top: $spacing-04;
    border-top: 5px solid $black;

    > p {

      &:first-of-type {

        .anchorjs-link {
          margin-left: -2.75em !important; // Ugly but need to override anchorJS
        }

        // Dropcap
        &:first-letter {
          padding-right: $spacing-02;
          float: left;
          color: $red;
          font-weight: 600;
          line-height: 1;
          @include typescale-01;
        }
      }


      &:last-of-type {
        margin-bottom: 0;
      }
    }

    p, li {
      // Highlight anchor link paragraph
      &:target {
        padding: $spacing-02;
        background-color: rgba($red, .1);
      }
    }

    li .anchorjs-link {
      margin-left: -2.75em !important; // Ugly but need to override anchorJS
    }

    .anchorjs-link {
      font-size: 1rem;
      @include font-sans;
    }
  }

  .page-sidebar-aside {
    display: none;

    @media only screen and (min-width: 768px) {
      display: inline-block;
    }
  }
}
