.page-content {

  // Asterisk fleuron
  &:after {
    display: block;
    margin: $spacing-05 auto;
    height: 1em;
    width: 1em;
    -webkit-print-color-adjust: exact;
    background-image: url("data:image/svg+xml,%3Csvg width='26' height='23' viewBox='0 0 26 23' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7.91421 22.3905C7.5562 22.3905 7.21242 22.276 6.92181 22.0638L6.8235 22.005C6.57463 21.8993 6.35421 21.7315 6.18293 21.512C5.94983 21.2138 5.7124 20.7001 5.90346 19.9469L5.95694 19.7366L9.68907 13.349L2.49285 13.3981C1.48562 13.3981 0.734375 12.6487 0.734375 11.6549V11.0047L0.811354 10.7703C1.06393 10.0038 1.71965 9.50843 2.48203 9.50843L9.68907 9.5572L6.12048 3.45012C5.8812 3.11703 5.76959 2.71405 5.8104 2.30894C5.84039 2.00929 5.98507 1.44435 6.61544 1.02777L6.84483 0.888544C7.13111 0.650718 7.49375 0.515465 7.88143 0.515465C8.35444 0.515465 8.78726 0.707784 9.16845 1.08752L9.31561 1.23406L12.9958 7.66946L16.629 1.32368C16.9722 0.810049 17.4999 0.515465 18.0774 0.515465C18.4354 0.515465 18.7801 0.630247 19.071 0.843163L19.481 1.08873L19.6154 1.22262C20.1716 1.77699 20.3138 2.57651 19.9781 3.25984L19.9255 3.35751L16.3031 9.5572L23.4991 9.50843C24.506 9.50843 25.2575 10.2576 25.2575 11.2514V11.6549C25.2575 12.6487 24.506 13.3981 23.5099 13.3981L16.3031 13.349L20.0068 19.6889L20.0578 19.8424C20.3333 20.6754 20.0186 21.5227 19.2914 21.9314L18.8323 22.206L18.6682 22.2597C17.8276 22.5353 16.9548 22.1929 16.5616 21.4721L12.9958 15.2368L9.35951 21.5872C9.01604 22.0977 8.48924 22.3905 7.91421 22.3905Z' fill='%23DB2333'/%3E%3C/svg%3E%0A");
    background-size: contain;
    background-repeat: no-repeat;
    vertical-align: middle;
    text-align: center;
    content: '';
  }
}
